@import '../../../../../../scss/trashie.scss';

.PostRegistration {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;

  &__explore-rewards {
    background-color: var(--trashie-light-blue);
  }

  &__gift-cards {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    overflow: hidden;

    &-title {
      color: var(--trashie-ui-faded-black-100);
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
    }
  }
}